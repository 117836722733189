import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { graphql } from "gatsby"

const ProjectPage = ({ data }) => (

  <Layout>
    <SEO
      title={`Projects`}
      description={`A short list of the various dashboard and web related things I've built over the years.`}
    />
    <div className={`flex flex-col items-center w-full`}>
      {data.allMarkdownRemark.edges.map(({ node }, index) => (
        <a key={index} title={`Visit ${node.frontmatter.name}`} href={node.frontmatter.url} target="_blank" rel="noopener noreferrer" className={`w-full md:5/6 lg:w-5/6 xl:w-7/12`}>
          <div className={`flex flex-col bg-white shadow px-6 py-10 md:p-10 mb-2 md:mb-6`}>
            <div className={`w-1/2 md:w-1/3`}>
              <Image filename={node.frontmatter.logo} alt={`${node.frontmatter.name} Logo`} />
            </div>
            <div className={`w-full`}>
              <div className={`flex flex-col mt-4`}>
                <div className={`flex flex-col sm:flex-row items-baseline mb-2`}>
                  <h2 className={`font-bold text-lg my-1 mr-2 whitespace-no-wrap`}>{node.frontmatter.name}</h2>
                  <div className={`text-sm text-gray-700`} title={`Launched in ${node.frontmatter.launched}`}>{node.frontmatter.launched}</div>
                </div>
                <p className="markdown text-gray-800" dangerouslySetInnerHTML={{ __html: node.html }} />
                </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  </Layout>
)

export default ProjectPage

export const query = graphql`
query MyProjects {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/src/projects/.*.md$/"}},
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          name
          url
          logo
          launched
        }
        html
      }
    }
  }
}
`